import "./App.scss";

import { StyledEngineProvider } from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import store from "store";

import { ThemeWrapper } from "./ThemeWrapper";

function App() {
  return (
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
          >
            <ThemeWrapper />
          </GoogleOAuthProvider>
        </Provider>
      </StyledEngineProvider>
    </HelmetProvider>
  );
}

export default App;
