import { ReactComponent as SelectArrowIcon } from "assets/svg/arrow-down.svg";
import { ReactComponent as CheckBoxIconChecked } from "assets/svg/check-box-icon.svg";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import colors from "theme/colors";

import { paletteLight } from "./paletteLight";

export const gradient = colors.gradientPrimary400;
export const lightGradient = colors.gradientPrimary500;

const getGradient = (opacityFirst, opacitySecond) =>
  `linear-gradient(128deg, rgba(156, 44, 243, ${
    opacityFirst || 1
  }) 0%, rgba(58, 111, 249, ${opacitySecond || 1}) 100%)`;
const getLightGradient = (opacityFirst, opacitySecond) =>
  `linear-gradient(128deg, rgba(173, 66, 255, ${
    opacityFirst || 1
  }) 0%, rgb(95, 137, 247, ${opacitySecond || 1}) 100%)`;

export const components = {
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        "& .MuiAutocomplete-inputRoot": {
          padding: 0,
          "& .MuiAutocomplete-input": {
            padding: "18px 16px",
          },
        },

        "& .MuiInputBase-adornedEnd": {
          position: "relative",
          padding: "0!important",
          height: "60px",

          "& .MuiAutocomplete-input": {
            paddingRight: "39px",
            height: "60px",
          },
          "& svg": {
            position: "absolute",
            right: "16px",
          },
        },
        "&.Mui-expanded": {
          "& svg": {
            transform: "rotate(-180deg)",
          },
        },
      },
    },
  },
  MuiContainer: {
    defaultProps: {
      maxWidth: "xl",
    },
    styleOverrides: {
      maxWidthLg: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      maxWidthSm: {
        paddingLeft: 16,
        paddingRight: 16,
      },
      maxWidthXl: {
        paddingLeft: 48,
        paddingRight: 48,
      },
    },
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        inter_32_600: "h1",
        inter_24_600: "h2",
        inter_20_600: "h3",
        inter_18_600: "p",
        inter_17_500: "p",
        inter_16_600: "p",
        inter_16_500: "p",
        inter_16_400: "p",
        inter_15_500: "p",
        inter_15_400: "p",
        inter_14_500: "p",
        inter_14_600: "p",
        inter_13_600: "p",
        inter_13_500: "p",
        inter_13_400: "p",
        inter_11_600: "p",
        inter_10_600: "p",
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        borderRadius: "10px",
        border: `1px solid ${colors.neutralGray300}`,
        background: colors.neutralGray50,
        boxShadow: "none",
        marginTop: "4px",

        "& ul": {
          padding: 0,
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px",
        color: colors.neutralGray800,
        height: "52px",
        boxSizing: "border-box",
        padding: "16px",

        "&:hover, &.Mui-focusVisible": {
          color: `${colors.neutralGray800} !important`,
          background: `linear-gradient(0deg, rgba(226, 232, 240, 0.50) 0%, rgba(226, 232, 240, 0.50) 100%) !important`,
        },
        "&.Mui-selected": {
          backgroundColor: colors.neutralGray300,
          color: "white",
          "&:hover": {
            backgroundColor: colors.neutralGray50,
          },
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      InputLabelProps: {
        shrink: true, // Prevent label from overlapping + visible placeholder
      },
    },
    styleOverrides: {
      root: {
        marginBottom: "16px",
        "& p": {
          marginLeft: 0,
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "18px",
          margin: "8px 0 0",
          display: "block",
        },
        "& .Mui-error p": {
          color: colors.brightRed,
        },
        "& .MuiInputLabel-root": {
          font: `600 14px / 1 "Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC"`,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: { fontWeight: 600 },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        boxSizing: "border-box",
        height: "60px",
        position: "relative",
        borderRadius: "10px",
        overflow: "hidden",
        paddingLeft: 0,
        paddingRight: 0,
        "@media (max-width:768px)": {
          height: "52px",
          "&.MuiInputBase-multiline": {
            height: "auto",
          },
        },
        "&.MuiInputBase-multiline": {
          height: "auto",
          padding: 0,
        },
        "&.MuiInputBase-adornedStart": {
          input: {
            paddingLeft: "48px",
          },
        },
        "&.MuiInputBase-adornedEnd": {
          input: {
            paddingRight: "48px",
          },
        },
        "& .MuiInputAdornment-root": {
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 3,
        },
        "& .MuiInputAdornment-positionStart": {
          left: "16px",
        },
        "& .MuiInputAdornment-positionEnd": {
          right: "16px",
        },
        "& fieldset": {
          position: "absolute",
          textAlign: "left",
          border: "none",
          zIndex: 1,
        },

        "& .MuiSelect-icon": {
          top: "unset",
          right: "16px",
        },

        "& .MuiSelect-select.MuiOutlinedInput-input": {
          borderRadius: "10px",
          height: "100%",
          display: "flex",
          alignItems: "center",
          border: `2px solid ${colors.neutralGray300}`,

          "&:hover, &[aria-expanded='true']": {
            border: `2px solid ${colors.neutralGray500}`,
          },
        },

        "& svg": {
          zIndex: 2,
        },

        "&.Mui-disabled svg": {
          opacity: 0.5,
        },

        "&.Mui-disabled .MuiSelect-select.MuiOutlinedInput-input": {
          opacity: 0.5,
          border: `2px solid ${colors.neutralGray300} !important`,
        },

        "& legend": {
          width: 0, // Collapse the width of the legend to remove the gap
        },
        "& input, textarea": {
          "&::placeholder": {
            opacity: 1, // Ensure placeholder is always visible
            color: "gray",
          },
        },

        "&.Mui-focused": {
          "& input, textarea": {
            borderColor: "transparent !important",
          },

          "& fieldset": {
            marginTop: "-1px",
            background: gradient,
          },
        },

        "&.Mui-error": {
          "& input, textarea": {
            borderColor: `${colors.brightRed}!important`,
            color: colors.brightRed,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
        },
      },
      input: {
        height: "60px",
        padding: "20px 16px",
        boxSizing: "border-box",
        lineHeight: "20px",
        fontWeight: 400,
        fontSize: "15px",
        background: "#ffffff padding-box",
        colors: colors.neutralGray900,
        border: `2px solid ${colors.neutralGray300}`,
        borderRadius: "10px",
        outline: "none",
        position: "relative",
        zIndex: 2,

        "@media (max-width:768px)": {
          height: "52px",
        },
        "&:not([disabled]):hover": {
          border: `2px solid ${colors.neutralGray500}`,
        },
      },
    },
  },
  MuiInputLabel: {
    defaultProps: {
      InputLabelProps: {
        shrink: true, // Always shrink labels
      },
    },
    styleOverrides: {
      root: {
        padding: 0,
        color: colors.neutralGray600,
        transform: "translate(0, -20px)",
        fontSize: "14px",
        zIndex: 1,
        top: "12px",
        position: "relative",

        "&.Mui-focused": {
          color: colors.neutralGray600,
        },

        "&.Mui-error": {
          color: colors.brightRed,
          fontSize: "14px",
        },
      },
    },
  },
  // MuiInput: {
  //   styleOverrides: {
  //     root: {
  //       color: "#fff",
  //       fontSize: "16px",
  //       paddingBottom: "16px",
  //       fontFamily: `"Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC"`,
  //       "&:before": {
  //         borderBottom: "1px solid #FFFFFF !important",
  //         borderColor: "#fff",
  //       },
  //       "&:after": {
  //         borderColor: "#70E8FC",
  //       },
  //       "&.Mui-error": {
  //         fontSize: "16px",
  //       },
  //     },
  //   },
  // },
  MuiLoadingButton: {
    defaultProps: {
      loadingIndicator: <LoadingIndicator />,
    },
    styleOverrides: {
      root: {
        opacity: "1 !important",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontFamily: `"Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC"`,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "18px",
        textTransform: "none",
        height: "46px",
        borderRadius: "8px",
        padding: "12px 29px",
        border: "none",
        flexShrink: 0,
        boxShadow: "none",

        "&.Mui-focusVisible": {
          boxShadow: "none",
        },

        "&.Mui-focusVisible .MuiTouchRipple-root": {
          display: "none", // Hide the ripple effect when focused
        },

        "&.MuiButton:hover": {
          boxShadow: "none",
        },

        "&.MuiButton-sizeLarge": {
          height: "64px",
          lineHeight: "18px",
        },

        "&.MuiButton-sizeMedium": {
          height: "54px",
        },

        "&.MuiButton-sizeSmall": {
          height: "46px",
        },

        // filter button with active state
        "&.MuiButton-filter": {
          height: "40px",
          color: colors.neutralGray800,
          fontSize: "15px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "20px",
          border: `1px solid ${colors.neutralGray200}`,
          background: colors.neutralGray50,

          "&.MuiLoadingButton-loading": {
            color: "transparent",

            "&.active": {
              color: "transparent",
            },
          },

          "&.active": {
            background: colors.primary500,
            borderColor: "transparent",
            color: colors.neutralGray50,
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "22px",
          },
        },

        "&.MuiButton-text": {
          padding: "0 3px",
          height: "22px",
          background: gradient,
          "-webkit-background-clip": "text",
          "-webkit-text-fill-color": "transparent",
          minWidth: "0",
          border: `2px solid transparent`,

          "&:not(.Mui-focusVisible):hover": {
            "&:before": {
              background: gradient,
              content: "''",
              position: "absolute",
              bottom: "2px",
              left: "3px",
              width: "calc(100% - 6px)",
              height: "1px",
            },
          },

          "&.Mui-focusVisible": {
            border: `2px solid ${colors.primary600}`,
          },
        },

        "&.MuiButton-contained": {
          background: `${lightGradient}, ${gradient}`,
          color: "#FFFFFF",

          "& .MuiTouchRipple-root": {
            color: "#082445",
          },

          "& svg *": {
            color: "#FFFFFF",
            stroke: "#FFFFFF",
          },

          "&:hover": {
            background: getLightGradient(0.8, 0.8),
            boxShadow: "none",
          },

          "&.Mui-focusVisible": {
            "&:before": {
              content: "''",
              position: "absolute",
              top: "2px",
              right: "2px",
              bottom: "2px",
              left: "2px",
              border: "2px solid white",
              borderRadius:
                "6px" /* Adjust to match the button's border-radius minus the inset */,
              pointerEvents:
                "none" /* Allows clicks to pass through to the button */,
            },
          },
        },

        "&.MuiButton-verify": {
          background: paletteLight.coral.main,
          color: "#111322",
          border: "none",
          fontWeight: 500,
        },
        "&.MuiButton-yellow": {
          background: "#F1FC70",
          color: "#111322",
          border: "none",
          fontWeight: 500,
        },

        "&.MuiButton-secondary": {
          background: getGradient(0.3, 0.3),
          color: colors.primary600,
          border: "none",
          fontFamily: `"Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC"`,

          "&:hover": {
            background: getGradient(0.2, 0.2),
          },

          "&.Mui-focusVisible": {
            boxShadow: `inset 0 0 0 2px`,
            background: `${getGradient(0.2, 0.2)} padding-box`,

            "&:before": {
              content: "''",
              position: "absolute",
              top: "2px",
              right: "2px",
              bottom: "2px",
              left: "2px",
              border: "2px solid white",
              borderRadius: "6px",
              pointerEvents: "none",
            },
          },
        },
        "&.MuiButton-outlined": {
          color: colors.primary600,
          background: gradient,
          backgroundClip: "text",
          "-webkit-background-clip": "text",
          "-webkit-text-fill-color": "transparent",
          border: `2px solid ${colors.primary600}`,

          "&.Mui-focusVisible": {
            borderWidth: "4px",
          },

          "&:hover": {
            background:
              "linear-gradient(128deg, rgba(173, 66, 255, 0.10) 30%, rgba(95, 137, 247, 0.10) 70%)",
            color: colors.primary600,
            "-webkit-background-clip": "unset",
            "-webkit-text-fill-color": "unset",
            transition: 0,
          },
          "&.MuiLoadingButton-loading": {
            background: getGradient(0.2, 0.2),
            color: "transparent",
            "-webkit-background-clip": "unset",
            "-webkit-text-fill-color": "unset",
          },
        },
        "&.MuiButton-outlined-red": {
          background: "none",
          color: paletteLight.coral.main,
          border: "1px solid #EE5261",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            opacity: 0.8,
          },
          ".MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
          },
        },
        "&.MuiButton-contained-danger": {
          background: paletteLight.coral.main,
          color: "#111322",
          fontWeight: 500,
          "&:hover": {
            background: paletteLight.coral.hover,
          },
        },
        "&.Mui-disabled": {
          opacity: "0.5 !important",
        },
      },
      contained: {
        "& .MuiTouchRipple-root": {
          color: "#082445",
        },
      },
      text: {
        "& .MuiTouchRipple-root": {
          color: colors.gradientPrimary400start,
        },
      },
      secondary: {
        "& .MuiTouchRipple-root": {
          color: colors.gradientPrimary400start,
        },
      },
      // TODO: might need to remove
      containedSecondary: {
        color: "#000646",
        "&:hover": {
          background: "#edfbff",
        },
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          color: "#111322",
          background: "#FFFFFF",
          "&:hover": {
            color: "#111322",
            background: "#edfbff",
          },
        },
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      IconComponent: SelectArrowIcon,
    },
    styleOverrides: {
      root: {
        borderRadius: "10px",
        border: "none",
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        background: "none",
        boxShadow: "none",
        border: "1px solid rgba(240, 241, 245, 0.1)",
        margin: 0,
        "@media (max-width:480px)": {
          border: "none",
        },
        "&.MuiAccordion-root": {
          borderRadius: "12px",
        },
        "&:before": {
          display: "none",
        },
        "&.Mui-expanded": {
          margin: 0,
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: "0 28px",
        minHeight: "82px",
        "@media (max-width:480px)": {
          minHeight: "41px",
          padding: "0",
        },
        "&.Mui-expanded": {
          boxShadow: "0 1px 0 0 rgba(240, 241, 245, 0.1)",
          minHeight: "82px",
          "@media (max-width:480px)": {
            boxShadow: "none",
            minHeight: "41px",
          },
        },
      },
      content: {
        "&.Mui-expanded": {
          "@media (max-width:480px)": {
            margin: "0px",
          },
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: "20px 28px",
        "@media (max-width:480px)": {
          padding: "16px",
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        margin: "0 0 16px",
        minHeight: "28px",
        ".MuiTabs-indicator": {
          background: paletteLight.tab.indicator.background,
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "none",
        font: `15px / 1 "Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC"`,
        minHeight: "32px",
        padding: "0px 8px",
        "&.Mui-selected": {
          fontWeight: 600,
          background:
            "-webkit-linear-gradient(128.02deg, #AD42FF 0%, #5F89F7 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        },
        "@media (max-width:768px)": {
          fontSize: "14px",
          padding: "6px 8px",
        },
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      icon: <span />,
      checkedIcon: <span />,
    },
    styleOverrides: {
      root: {
        color: "#7681A3",
        background: "transparent",
        position: "relative",
        padding: 0,
        margin: "9px",
        "& input": {
          zIndex: 4,
          width: "24px",
          height: "24px",
          position: "relative",
        },

        // border for radio
        "&:before": {
          content: "''",
          zIndex: 0,
          borderRadius: "50%",
          width: "24px",
          height: "24px",
          position: "absolute",
          top: 0,
          left: 0,
          background: "#7681A3",
        },

        // unchecked radio white dot
        "&:after": {
          content: "''",
          zIndex: 1,
          borderRadius: "50%",
          width: "24px",
          height: "24px",
          background: "#FFF padding-box",
          border: "solid 2px transparent",
          boxSizing: "border-box",
          position: "absolute",
        },

        // hovered unchecked radio border
        "&:hover:before": {
          background: gradient,
        },

        // hovered unchecked radio white dot
        "&:hover:after": {
          border: "solid 3px transparent",
        },

        // focused radio border
        "&:focus-within:before": {
          background: gradient,
        },

        // selected border
        "&.Mui-checked:before": {
          background: gradient,
        },

        // selected radio dot
        "&.Mui-checked:after": {
          width: "12px",
          height: "12px",
          boxSizing: "content-box",
        },

        // hover selected border
        "&.Mui-checked:hover:before": {
          background: lightGradient,
        },

        "&.Mui-disabled": {
          opacity: 0.5,
        },
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true, // Disables ripple globally for all checkboxes
      checkedIcon: <CheckBoxIconChecked />,
      icon: <span />,
    },
    styleOverrides: {
      root: {
        width: "24px",
        height: "24px",
        boxSizing: "border-box",
        position: "relative",
        zIndex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
        "& input": {
          zIndex: 4,
        },

        "& svg": {
          zIndex: 3,
          minWidth: "12px",
          width: "12px",
        },

        "&:before": {
          content: "''",
          zIndex: 0,
          borderRadius: "6px",
          width: "24px",
          height: "24px",
          position: "absolute",
          top: 0,
          left: 0,
          background: "#7681A3",
        },
        "&:after": {
          borderRadius: "6px",
          background: "#FFF padding-box",
          border: "2px solid transparent",
          boxSizing: "border-box",
          content: "''",
          zIndex: 2,
          width: "24px",
          height: "24px",
          position: "absolute",
          top: "0px",
          left: "0px",
        },

        "&:hover:after": {
          content: "''",
          zIndex: 2,
          border: "2px solid transparent",
          position: "absolute",
        },

        "&:hover:before": {
          content: "''",
          zIndex: 0,
          borderRadius: "6px",
          width: "24px",
          height: "24px",
          position: "absolute",
          top: 0,
          left: 0,
          background: `${gradient} !important`,
        },

        // styles for focused checkbox
        "&:focus-within::before": {
          content: "''",
          zIndex: 0,
          borderRadius: "6px",
          width: "24px",
          height: "24px",
          position: "absolute",
          top: 0,
          left: 0,
          background: gradient,
        },

        "&:focus-within::after": {
          content: "''",
          borderRadius: "4px",
          zIndex: 2,
          width: "20px",
          height: "20px",
          position: "absolute",
          top: "0px",
          left: "0px",
          background: "#FFF",
          marginLeft: "2px",
          marginTop: "2px",
        },

        // styles for checked checkbox
        "&.Mui-checked::after": {
          content: "''",
          position: "absolute",
          display: "none",
        },

        "&.Mui-checked::before": {
          content: "''",
          background: gradient,
        },

        "&.Mui-checked:hover::before": {
          content: "''",
          background: `${lightGradient} !important`,
        },

        "&.Mui-checked:focus-within::before": {
          background: `${lightGradient} !important`,
        },

        "&.Mui-disabled": {
          opacity: 0.5,
        },

        "&.Mui-disabled::before": {
          content: "''",
          zIndex: 0,
          borderRadius: "6px",
          width: "24px",
          height: "24px",
          position: "absolute",
          top: 0,
          left: 0,
          background: "#7681A3", // Disabled state background color
        },

        "&.Mui-disabled::after": {
          content: "''",
          borderRadius: "4px",
          zIndex: 2,
          width: "20px",
          height: "20px",
          position: "absolute",
          top: "0px",
          left: "0px",
          background: "#FFF",
          marginLeft: "2px",
          marginTop: "2px",
        },

        "&.Mui-checked.Mui-disabled:before": {
          background: gradient,
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        background: "transparent",
        padding: "6px",
        borderRadius: "8px",

        "& svg": {
          width: "24px",
          height: "24px",
          color: colors.neutralGray600,
        },

        "&:hover": {
          background: colors.neutralGray200,
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: "48px",
        maxWidth: "48px",
        height: "26px",
        maxHeight: "26px",
        padding: 0,
        margin: 8,

        "&:hover": {
          "& .MuiSwitch-switchBase:not(.Mui-disabled) .MuiSwitch-thumb": {
            background: colors.neutralGray50,
          },

          "& .MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) + .MuiSwitch-track":
            {
              background: lightGradient,
            },
        },
      },
      switchBase: {
        padding: 1,
        borderRadius: "100px",
        left: "2px",
        top: "2px",
        boxShadow: "none",

        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: "0.5 !important",
        },

        "&.Mui-checked": {
          transform: "translateX(22px)",
          color: colors.neutralGray50,

          "& .MuiSwitch-thumb": {
            background: colors.neutralGray50,
          },

          "& + .MuiSwitch-track": {
            "&.Mui-disabled": {
              opacity: 0.5,
            },
            background: gradient,
          },
        },
      },
      thumb: {
        width: "20px",
        height: "20px",
        background: "#DCEAF9",
        left: 0,
        boxShadow: "none",
      },
      track: {
        borderRadius: "100px",
        backgroundColor: "#7681A3 !important",
        opacity: "1 !important",
      },
    },
  },
  MuiSnackbar: {
    styleOverrides: {
      root: {
        "& .MuiSnackbarContent-root": {
          background: colors.neutralWhite,
          padding: "16px 24px",
          borderRadius: "10px",
          boxShadow:
            "0px 4px 112px 0px rgba(15, 23, 42, 0.30), 0px 4px 20px 0px rgba(15, 23, 42, 0.06)",
        },
        "& .MuiSnackbarContent-message": {
          fontFamily: `"Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC"`,
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: 1.3,
          color: colors.neutralGray800,
          padding: 0,
        },
        "& .MuiSnackbarContent-action": {
          paddingLeft: "32px",
        },
      },
    },
  },
};
