import { PATHS } from "./index";

export const NOTIFICATION_STATUS = {
  NEW: "NEW",
  SEEN: "SEEN",
};
export const NOTIFICATION_TYPE = {
  ORDER_COMPLETE_BUY: "ORDER_COMPLETE_BUY",
  ORDER_COMPLETE_SELL: "ORDER_COMPLETE_SELL",
  ORDER_EXPIRED_BUY: "ORDER_EXPIRED_BUY",
  ORDER_EXPIRED_SELL: "ORDER_EXPIRED_SELL",
  DROP_LAUNCH: "DROP_LAUNCH",
  DROP_STAGE_2: "DROP_STAGE_2",
  DROP_ENDING: "DROP_ENDING",
  DROP_WINNER: "DROP_WINNER",
  BUY_ORDER_FAILED: "BUY_ORDER_FAILED",
  SELL_ORDER_FAILED: "SELL_ORDER_FAILED",
  DROP_BID_FAILED: "DROP_BID_FAILED",
  FIRST_500: "FIRST_500",
  REFERRAL_ACCEPTED: "REFERRAL_ACCEPTED",
  REFERRAL_COMPLETE: "REFERRAL_COMPLETE",
  KYC_APPROVED: "KYC_APPROVED",
  FINISH_KYC: "FINISH_KYC",
  KYC_FAILED: "KYC_FAILED",
  CHALLENGE_AVAILABLE_NEW: "CHALLENGE_AVAILABLE_NEW",
  CHALLENGE_WEEKLY: "CHALLENGE_WEEKLY",
  CHALLENGE_ACHIEVING: "CHALLENGE_ACHIEVING",
  CHALLENGE_REMINDER: "CHALLENGE_REMINDER",
  CHALLENGE_XP_POINTS: "CHALLENGE_XP_POINTS",
  CHALLENGE_BADGE: "CHALLENGE_BADGE",
  CHALLENGE_BADGE_NEW: "CHALLENGE_BADGE_NEW",
  CHALLENGE_FAILED: "CHALLENGE_FAILED",
  CHALLENGE_COMPLETED: "CHALLENGE_COMPLETED",
  DROP_PHASE_1_FULL: "DROP_PHASE_1_FULL",
  DROP_OUTBID: "DROP_OUTBID",
  DROP_LOSER: "DROP_LOSER",
  DROP_BOTTOM_25: "DROP_BOTTOM_25",
  DROP_MIDDLE_50: "DROP_MIDDLE_50",
  ADD_FUNDS_PROCESSED: "ADD_FUNDS_PROCESSED",
  ADD_FUNDS_FAILED: "ADD_FUNDS_FAILED",
  FRIEND_REQUEST: "FRIEND_REQUEST",
  FRIENDSHIP_ACCEPTED: "FRIENDSHIP_ACCEPTED",
  SWITCH_REQUEST_RECEIVED: "SWITCH_REQUEST_RECEIVED",
  SWITCH_REQUEST_ACCEPTED: "SWITCH_REQUEST_ACCEPTED",
  SWITCH_REQUEST_REJECTED: "SWITCH_REQUEST_REJECTED",
  SWITCH_REQUEST_CANCELLED: "SWITCH_REQUEST_CANCELLED",
  BARTER_REQUEST_ACCEPTED: "BARTER_REQUEST_ACCEPTED",
  BARTER_REQUEST_REJECTED: "BARTER_REQUEST_REJECTED",
  BARTER_REQUEST_RECEIVED: "BARTER_REQUEST_RECEIVED",
  BARTER_NEW_MATCH: "BARTER_NEW_MATCH",
  PAYMENT_PROCESSED: "PAYMENT_PROCESSED",
  PAYMENT_FAILED: "PAYMENT_FAILED",
  WITHDRAW_FUNDS_PROCESSED: "WITHDRAW_FUNDS_PROCESSED",
};
// TODO::refactoring
export const NOTIFICATION_REDIRECT = {
  [NOTIFICATION_TYPE.DROP_PHASE_1_FULL]: {
    redirect: ({ cardId }) => {
      return PATHS.CARD_ID.replace(":id", cardId);
    },
  },
  [NOTIFICATION_TYPE.ORDER_COMPLETE_BUY]: {
    redirect: ({ cardId }) => {
      return PATHS.CARD_ID.replace(":id", cardId);
    },
  },
  [NOTIFICATION_TYPE.ORDER_COMPLETE_SELL]: {
    redirect: ({ cardId }) => {
      return PATHS.CARD_ID.replace(":id", cardId);
    },
  },
  [NOTIFICATION_TYPE.ORDER_EXPIRED_BUY]: {
    redirect: () => {
      return PATHS.HOME;
    },
  },
  [NOTIFICATION_TYPE.ORDER_EXPIRED_SELL]: {
    redirect: ({ cardId }) => {
      return cardId ? PATHS.CARD_ID.replace(":id", cardId) : PATHS.HOME;
    },
  },
  [NOTIFICATION_TYPE.DROP_LAUNCH]: {
    redirect: ({ cardId }) => {
      return PATHS.CARD_ID.replace(":id", cardId);
    },
  },
  [NOTIFICATION_TYPE.DROP_STAGE_2]: {
    redirect: ({ cardId }) => {
      return PATHS.CARD_ID.replace(":id", cardId);
    },
  },
  [NOTIFICATION_TYPE.DROP_OUTBID]: {
    redirect: ({ cardId }) => {
      return PATHS.CARD_ID.replace(":id", cardId);
    },
  },
  [NOTIFICATION_TYPE.DROP_ENDING]: {
    redirect: ({ cardId }) => {
      return PATHS.CARD_ID.replace(":id", cardId);
    },
  },
  [NOTIFICATION_TYPE.DROP_WINNER]: {
    redirect: ({ cardId }) => {
      return PATHS.CARD_ID.replace(":id", cardId);
    },
  },
  [NOTIFICATION_TYPE.DROP_LOSER]: {
    redirect: ({ cardId }) => {
      return PATHS.CARD_ID.replace(":id", cardId);
    },
  },
  [NOTIFICATION_TYPE.DROP_BOTTOM_25]: {
    redirect: ({ cardId }) => {
      return PATHS.CARD_ID.replace(":id", cardId);
    },
  },
  [NOTIFICATION_TYPE.DROP_MIDDLE_50]: {
    redirect: ({ cardId }) => {
      return PATHS.CARD_ID.replace(":id", cardId);
    },
  },
  [NOTIFICATION_TYPE.BUY_ORDER_FAILED]: {
    redirect: () => {
      return PATHS.HOME;
    },
  },
  [NOTIFICATION_TYPE.SELL_ORDER_FAILED]: {
    redirect: () => {
      return PATHS.HOME;
    },
  },
  [NOTIFICATION_TYPE.DROP_BID_FAILED]: {
    redirect: ({ cardId }) => {
      return PATHS.CARD_ID.replace(":id", cardId);
    },
  },
  [NOTIFICATION_TYPE.REFERRAL_ACCEPTED]: {
    redirect: () => {
      return PATHS.REFERRALS;
    },
  },
  [NOTIFICATION_TYPE.REFERRAL_COMPLETE]: {
    redirect: () => {
      return PATHS.REFERRALS;
    },
  },
  [NOTIFICATION_TYPE.CHALLENGE_COMPLETED]: {
    redirect: () => {
      return PATHS.CHALLENGES;
    },
  },
  [NOTIFICATION_TYPE.FRIEND_REQUEST]: {
    redirect: () => {
      return `/connections`;
    },
  },
  [NOTIFICATION_TYPE.FRIENDSHIP_ACCEPTED]: {
    redirect: () => {
      return `/connections`;
    },
  },
  [NOTIFICATION_TYPE.ADD_FUNDS_PROCESSED]: {
    redirect: () => {
      return PATHS.SETTINGS_TRANSFERS;
    },
  },
  [NOTIFICATION_TYPE.SWITCH_REQUEST_ACCEPTED]: {
    redirect: ({ swapId }) => {
      return `/swaps/${swapId}`;
    },
  },
  [NOTIFICATION_TYPE.SWITCH_REQUEST_RECEIVED]: {
    redirect: ({ swapId }) => {
      return `/swaps/${swapId}`;
    },
  },
  [NOTIFICATION_TYPE.SWITCH_REQUEST_CANCELLED]: {
    redirect: ({ swapId }) => {
      return `/swaps/${swapId}`;
    },
  },
  [NOTIFICATION_TYPE.SWITCH_REQUEST_REJECTED]: {
    redirect: ({ swapId }) => {
      return `/swaps/${swapId}`;
    },
  },
  [NOTIFICATION_TYPE.BARTER_NEW_MATCH]: {
    redirect: () => {
      return `/barter/matches`;
    },
  },
  [NOTIFICATION_TYPE.PAYMENT_FAILED]: {
    redirect: () => {
      return PATHS.SETTINGS_TRANSFERS;
    },
  },
  [NOTIFICATION_TYPE.PAYMENT_PROCESSED]: {
    redirect: () => {
      return PATHS.SETTINGS_TRANSFERS;
    },
  },
  [NOTIFICATION_TYPE.WITHDRAW_FUNDS_PROCESSED]: {
    redirect: () => {
      return PATHS.SETTINGS_TRANSFERS;
    },
  },
};
