import { ReactComponent as LoaderIconGradient } from "assets/svg/loader-icon.svg";
import React from "react";

import styles from "./loading-indicator.module.scss";

const LoadingIndicator = () => {
  return (
    <span className={styles.indicatorWrapper}>
      <LoaderIconGradient className={styles.indicator} />
    </span>
  );
};

export default LoadingIndicator;
