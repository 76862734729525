import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import theme from "theme";

import { DEFAULT_TITLE_TAG } from "./constants";
import GlobalComponents from "./GlobalComponents/GlobalComponents";
import { getTheme } from "./redux/selectors/appSelectors";
import Router from "./Router";

export const ThemeWrapper = () => {
  const activeTheme = useSelector(getTheme);

  const currentTheme = createTheme(theme[activeTheme]);

  return (
    <ThemeProvider theme={currentTheme}>
      <Helmet>
        <title>{DEFAULT_TITLE_TAG}</title>
      </Helmet>
      <GlobalComponents />
      <Router />
    </ThemeProvider>
  );
};
