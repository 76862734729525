import { googleLogout } from "@react-oauth/google";
import {
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import axios from "axios";
import up from "services/userPool";
import { v4 as uuidv4 } from "uuid";

import { EMULATE } from "../constants";
import { api } from "./api.service";
let userPool = up;

const API_URL = process.env.REACT_APP_API_HOST;

const ACCESS_TOKEN = "dapple-access-token";
const REFRESH_TOKEN = "dapple-refresh-token";
const ID_TOKEN = "dapple-id-token";
const AUTH_USERNAME = "auth-username";
export const UUID = "uuid";
export const INVITE_CODE = "invite-code";
export const ZENDESK_TOKEN = "zendesk-token";
const isTelegramAuth = "telegram-onboarding-completed";
const GOOGLE_ACCESS_TOKEN = "google-access-token";
const GOOGLE_REFRESH_TOKEN = "google-refresh-token";

export const logout = () => {
  window.zE && window.zE("messenger", "logoutUser");
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(ZENDESK_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(ID_TOKEN);
  localStorage.removeItem(AUTH_USERNAME);
  removeEmulateId();
  removeInfluencerEmulateId();
  localStorage.removeItem(GOOGLE_ACCESS_TOKEN);
  localStorage.removeItem(GOOGLE_REFRESH_TOKEN);
  localStorage.removeItem("from");
  googleLogout();
};

const getParamsFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const emulateId = urlParams.get("emulateId");
  const username = urlParams.get("username");
  const refreshToken = urlParams.get("refreshToken");
  const idToken = urlParams.get("idToken");
  const accessToken = urlParams.get("accessToken");
  const userPoolId = urlParams.get("userPoolId");
  const clientId = urlParams.get("clientId");
  const emulateInfluencerId = urlParams.get("emulateInfluencerId");
  const googleRefreshToken = urlParams.get("googleRefreshToken");
  const googleAccessToken = urlParams.get("googleAccessToken");

  return {
    emulateId,
    username,
    refreshToken,
    idToken,
    accessToken,
    userPoolId,
    clientId,
    emulateInfluencerId,
    googleRefreshToken,
    googleAccessToken,
  };
};

const handleEmulation = () => {
  const {
    emulateId,
    username,
    refreshToken,
    idToken,
    accessToken,
    userPoolId,
    clientId,
    emulateInfluencerId,
    googleRefreshToken,
    googleAccessToken,
  } = getParamsFromURL();

  const setEmulateParams = () => {
    if (emulateInfluencerId) {
      setInfluencerEmulateId(emulateInfluencerId);
      removeEmulateId();
    } else if (emulateId) {
      setEmulateId(emulateId);
      removeInfluencerEmulateId();
    }
  };

  if (googleAccessToken && googleRefreshToken) {
    setGoogleAccessToken(googleAccessToken);
    setGoogleRefreshToken(googleRefreshToken);
    setEmulateParams();
    return;
  }
  if (refreshToken && idToken && accessToken) {
    setAuth(
      {
        idToken,
        accessToken,
        refreshToken,
        username,
      },
      true
    );
    setEmulateParams();

    if (username && userPoolId && clientId) {
      userPool = new CognitoUserPool({
        UserPoolId: userPoolId,
        ClientId: clientId,
      });
      const userData = {
        Username: username,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.refreshSession(
        new CognitoRefreshToken({ RefreshToken: refreshToken }),
        (err, session) => {
          setAuth(session);
        }
      );
    }
  }
};

export const getTelegramAuth = () => {
  return localStorage.getItem(isTelegramAuth);
};

export const isAuthenticated = () => {
  handleEmulation();
  if (window.Telegram?.WebApp.initData) {
    return getTelegramAuth();
  } else if (getGoogleAccessToken()) {
    return getGoogleAccessToken();
  } else {
    return getAccessToken() != null;
  }
};

export const setTgOnboardingCompleted = () => {
  return localStorage.setItem(isTelegramAuth, isTelegramAuth);
};

export const setAccessToken = (token) => {
  return localStorage.setItem(ACCESS_TOKEN, token);
};

export const setIdToken = (token) => {
  return localStorage.setItem(ID_TOKEN, token);
};

export const getIdToken = () => {
  return (
    localStorage.getItem(ID_TOKEN) ||
    getTelegramAuth() ||
    getGoogleAccessToken()
  );
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const setRefreshToken = (token) => {
  return localStorage.setItem(REFRESH_TOKEN, token);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const setAuthUsername = (token) => {
  return localStorage.setItem(AUTH_USERNAME, token);
};

export const getAuthUsername = () => {
  return localStorage.getItem(AUTH_USERNAME);
};

export const setUUID = () => {
  return localStorage.setItem(UUID, uuidv4());
};

export const setInviteCode = (code) => {
  return localStorage.setItem(INVITE_CODE, code);
};

export const getInviteCode = () => {
  return localStorage.getItem(INVITE_CODE);
};

export const removeInviteCode = () => {
  return localStorage.removeItem(INVITE_CODE);
};

export const setZendeskToken = (token) => {
  return localStorage.setItem(ZENDESK_TOKEN, token);
};

export const getZendeskToken = () => {
  return localStorage.getItem(ZENDESK_TOKEN);
};

export const setEmulateId = (emulateId) => {
  return localStorage.setItem(EMULATE.LOCAL_STORAGE_ID, emulateId);
};

export const getEmulateId = () => {
  return localStorage.getItem(EMULATE.LOCAL_STORAGE_ID);
};

export const removeEmulateId = () => {
  return localStorage.removeItem(EMULATE.LOCAL_STORAGE_ID);
};

export const setInfluencerEmulateId = (influencerEmulateId) => {
  return localStorage.setItem(
    EMULATE.INFLUENCER_EMULATE_ID,
    influencerEmulateId
  );
};

export const getInfluencerEmulateId = () => {
  return localStorage.getItem(EMULATE.INFLUENCER_EMULATE_ID);
};

export const removeInfluencerEmulateId = () => {
  return localStorage.removeItem(EMULATE.INFLUENCER_EMULATE_ID);
};

export const isEmulationMode = () => {
  return getEmulateId() || getInfluencerEmulateId();
};

export const getGoogleAccessToken = () => {
  return localStorage.getItem(GOOGLE_ACCESS_TOKEN);
};

export const setGoogleAccessToken = (token) => {
  return localStorage.setItem(GOOGLE_ACCESS_TOKEN, token);
};

export const getGoogleRefreshToken = () => {
  return localStorage.getItem(GOOGLE_REFRESH_TOKEN);
};

export const setGoogleRefreshToken = (token) => {
  return localStorage.setItem(GOOGLE_REFRESH_TOKEN, token);
};

export const clearTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(ID_TOKEN);
  localStorage.removeItem(UUID);
  localStorage.removeItem(INVITE_CODE);
  localStorage.removeItem(ZENDESK_TOKEN);
  localStorage.removeItem(AUTH_USERNAME);
  localStorage.removeItem(GOOGLE_ACCESS_TOKEN);
  localStorage.removeItem(GOOGLE_REFRESH_TOKEN);
};

export const setAuth = (data, isCrossDomain = false) => {
  const accessToken = isCrossDomain
    ? data.accessToken
    : data.getAccessToken().getJwtToken();
  const idToken = isCrossDomain
    ? data.idToken
    : data.getIdToken().getJwtToken();
  const refreshToken = isCrossDomain
    ? data.refreshToken
    : data.getRefreshToken().getToken();
  const username = isCrossDomain
    ? data.username
    : data.accessToken.payload.username;
  accessToken && setAccessToken(accessToken);
  idToken && setIdToken(idToken);
  refreshToken && setRefreshToken(refreshToken);
  username && setAuthUsername(username);
  setUUID();
};

export const postPreSignupCheck = (params) => {
  return api
    .post(`${API_URL}/public/users/pre-signup-check`, params)
    .then((response) => response?.data);
};

export const refreshGoogleToken = async () => {
  try {
    const refreshToken = getGoogleRefreshToken();
    if (!refreshToken) {
      throw new Error("No refresh token available");
    }

    const response = await axios.post(
      `${API_URL}/public/google-refresh-token`,
      {
        refresh_token: refreshToken,
      }
    );
    const { id_token } = response.data;
    setGoogleAccessToken(id_token);
  } catch (error) {
    console.error("Error refreshing Google token:", error);
  }
};
