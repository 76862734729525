import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import { EMULATE } from "../constants";
import {
  clearTokens,
  getEmulateId,
  getGoogleAccessToken,
  getIdToken,
  getInfluencerEmulateId,
  refreshGoogleToken,
  setAuth,
} from "./auth.service";
import UserPool from "./userPool";

export const API_AWS_AUTH_URL =
  process.env.REACT_APP_ENV === "staging"
    ? "https://zoop-app-staging.auth.eu-west-1.amazoncognito.com"
    : "https://zoop-app-production.auth.eu-west-1.amazoncognito.com";

const API_URL = process.env.REACT_APP_API_HOST;
const API_URL_CELEBRITY = process.env.REACT_APP_API_HOST_CELEBRITY;
const anonymousEndpoints = [`${API_URL}/app-users/status`];
/**
 * Adds authorization headers to API calls
 */

const emulateInterceptor = (request) => {
  const appUserid = getEmulateId();
  const influencerId = getInfluencerEmulateId();
  if (influencerId) {
    request.headers[EMULATE.INFLUENCER_HEADER] = influencerId;
  } else if (appUserid) {
    request.headers[EMULATE.HEADER] = appUserid;
  }
  return request;
};

const authInterceptor = async (request) => {
  // if (request.baseURL !== API_URL) {
  //   return;
  // }
  const isAnonymous =
    anonymousEndpoints.includes(request.url || "") ||
    new RegExp(`^${API_URL}/public/`).test(request.url);
  const idToken = getIdToken();
  if (isAnonymous && !idToken) {
    return request;
  }

  if (!idToken) {
    clearTokens();
    return Promise.reject(401);
  }

  const googleAuthToken = getGoogleAccessToken();

  request.headers["Authorization"] = window.Telegram?.WebApp?.initDataUnsafe
    ?.user?.id
    ? `Telegram ${window.Telegram.WebApp.initData}`
    : googleAuthToken
    ? `google ${googleAuthToken}`
    : `Bearer ${idToken}`;

  return request;
};

/**
 * Refresh expired tokens
 *
 * @param failedRequest
 * @returns
 */
function refreshExpiredTokens(failedRequest) {
  return new Promise(async (resolve, reject) => {
    const googleAuthToken = getGoogleAccessToken();
    if (googleAuthToken) {
      try {
        // Attempt to refresh Google token
        const newGoogleAuthToken = await refreshGoogleToken();
        failedRequest.response.config.headers[
          "Authorization"
        ] = `google ${newGoogleAuthToken}`;
        return resolve(failedRequest);
      } catch (error) {
        console.error("Failed to refresh Google token:", error);
        clearTokens();
        return reject(new Error("Failed to refresh Google token"));
      }
    }
    let cognitoUser = UserPool().getCurrentUser();
    if (!cognitoUser) {
      clearTokens();
      reject(new Error("No authenticated user found"));
      return;
    }
    cognitoUser?.getSession((err, res) => {
      if (!res) return clearTokens() && reject(err);
      const refresh_token = res.getRefreshToken();
      cognitoUser?.refreshSession(refresh_token, (err, session) => {
        const { idToken } = session;
        if (err) {
          clearTokens() && reject(err);
        } else {
          setAuth(session);
          failedRequest.response.config.headers["Authorization"] =
            "Bearer " + idToken.jwtToken;
          resolve(failedRequest);
        }
      });
    });
  });
}

export const getAppUsersStatus = (params) => {
  return api
    .get(`${API_URL}/app-users/status`, {
      params: { ...params },
    })
    .then((response) => response.data);
};

/**
 * Setup an API instance
 */
export const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    "Content-Type": "application/json",
    "ii-api-version": process.env.REACT_APP_API_VERSION,
    "ii-api-language": localStorage.getItem("ii-language"),
  },
});

/**
 * Setup an API instance for dashboard
 */
export const apiDashboard = axios.create({
  baseURL: API_URL_CELEBRITY,
  headers: {
    "Content-Type": "application/json",
    "ii-api-version": process.env.REACT_APP_API_VERSION_CELEBRITY,
  },
});

/** Add interceptor */
api.interceptors.request.use(authInterceptor);
api.interceptors.request.use(emulateInterceptor);
createAuthRefreshInterceptor(api, refreshExpiredTokens);
createAuthRefreshInterceptor(apiDashboard, refreshExpiredTokens);
