import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { setLoader } from "redux/appSlice";
import { getStripeAccountLink } from "services/api/paymentsApi";
import { isAuthenticated } from "services/auth.service";

import { PATHS } from "../constants";
import { useTelegram } from "./hooks/useTelegram";

export const RequireAuth = () => {
  let location = useLocation();
  const { user } = useTelegram();

  if (!isAuthenticated()) {
    const to = user
      ? PATHS.TELEGRAM_ONBOARDING_SPLASH
      : location.state?.redirectTo || PATHS.SIGN_UP;
    const isBackRoute = location.pathname !== PATHS.SETTINGS;

    if (isBackRoute) {
      localStorage.setItem("from", location.pathname);
      return <Navigate to={to} state={{ backRoute: location }} replace />;
    }
    return <Navigate to={to} replace />;
  }

  return <Outlet />;
};

export const PublicRoute = () => {
  let location = useLocation();

  if (isAuthenticated()) {
    return <Navigate to="/home" state={{ backRoute: location }} replace />;
  }
  return <Outlet />;
};
export const StripeOnboardingReturnUrl = ({
  path = PATHS.REFERRALS_CREATORS,
}) => {
  return <Navigate to={path} replace />;
};
/**
 * Redirect back to stripe account url in cases when:
 * The link is expired (a few minutes went by since the link was created).
 * The user already visited the URL (the user refreshed the page or clicked back or forward in the browser).\
 * Your platform is no longer able to access the account.
 * The account has been rejected.
 */
export const StripeOnboardingRefreshUrl = ({
  path = PATHS.REFERRALS_CREATORS,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoader(true));
    getStripeAccountLink()
      .then((res) => {
        if (res?.url) window.location.href = res.url;
      })
      .catch(() => {
        return <Navigate to={path} replace />;
      })
      .finally(() => dispatch(setLoader(false)));
  }, []);
  return <></>;
};
