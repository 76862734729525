import { paletteDark } from "./paletteDark";

export const components = {
  MuiContainer: {
    defaultProps: {
      maxWidth: "xl",
    },
    styleOverrides: {
      maxWidthLg: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      maxWidthSm: {
        paddingLeft: 16,
        paddingRight: 16,
      },
      maxWidthXl: {
        paddingLeft: 48,
        paddingRight: 48,
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        color: "#fff",
        fontSize: "16px",
        paddingBottom: "16px",
        fontFamily: `"Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC"`,
        "&:before": {
          borderBottom: "1px solid #FFFFFF !important",
          borderColor: "#fff",
        },
        "&:after": {
          borderColor: "#70E8FC",
        },
        "&.Mui-error": {
          fontSize: "16px",
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontFamily: `"Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC"`,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "20px",
        textTransform: "none",
        height: "54px",
        borderRadius: "10px",
        padding: "12px 29px",
        color: "#111322",
        background: "#70E8FC",
        border: "none",
        flexShrink: 0,
        "&.MuiButton-sizeSmall": {
          height: "44px",
        },
        "&.MuiButton-sizeXsmall": {
          height: "32px",
          fontSize: "18px",
          borderRadius: "6px",
          padding: "5px 8px",
          minWidth: "auto",
        },
        "&.MuiButton-text": {
          background: "none",
          padding: 0,
          height: "auto",
          color: "#00D3F3",
        },
        "&.MuiButton-contained": {
          background: "#70E8FC",
          color: "#111322",
          border: "none",
          fontWeight: 500,
          fontSize: "14px",
          "&:hover": {
            background: "#00D3F3",
          },
          "&Dark": {
            background: "#3d4bb5",
            color: "#fff",
            "&:hover": {
              background: "#3d4bb5",
            },
          },
          "&.MuiButton-containedSurface": {
            background: "#21264E",
            color: "white",
            "&:hover": {
              background: "#21264e80",
            },
            "&.MuiButton-sizeXsmall": {
              height: "27px",
              fontSize: "14px",
              borderRadius: "5px",
              color: "#70E8FC",
            },
          },
        },
        "&.MuiButton-verify": {
          background: paletteDark.coral.main,
          color: "#111322",
          border: "none",
          fontWeight: 500,
        },
        "&.MuiButton-yellow": {
          background: "#F1FC70",
          color: "#111322",
          border: "none",
          fontWeight: 500,
        },
        "&.MuiButton-secondary": {
          background: "#fff",
          color: "#111322",
          border: "none",
          fontFamily: `"Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC"`,
          fontWeight: 500,
          fontSize: "14px",
          height: "40px",
        },
        "&.MuiButton-outlined": {
          background: "none",
          color: "#FFFFFF",
          border: "1px solid #FFFFFF",
          fontSize: "14px",
          svg: {
            path: {
              stroke: "#fff",
            },
          },
          "&:hover": {
            color: "#70E8FC",
            borderColor: "#70E8FC",
            svg: {
              path: {
                stroke: "#70E8FC",
              },
            },
          },
          ".MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
          },
        },
        "&.MuiButton-outlined-red": {
          background: "none",
          color: paletteDark.coral.main,
          border: "1px solid #EE5261",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            opacity: 0.8,
          },
          ".MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
          },
        },
        "&.MuiButton-contained-danger": {
          background: paletteDark.coral.main,
          color: "#111322",
          fontWeight: 500,
          "&:hover": {
            background: paletteDark.coral.hover,
          },
        },
        "&.Mui-disabled": {
          opacity: 0.5,
        },
      },
      containedPrimary: {
        "&:hover": {},
      },
      containedSecondary: {
        color: "#000646",
        "&:hover": {
          background: "#edfbff",
        },
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          color: "#111322",
          background: "#FFFFFF",
          "&:hover": {
            color: "#111322",
            background: "#edfbff",
          },
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        borderRadius: "10px",
        color: "#FFFFFF",
        ".MuiOutlinedInput-notchedOutline": {
          border: "1px solid #FFFFFF",
        },
        ".MuiOutlinedInput-notchedOutline span": {
          border: "1px solid #FFFFFF",
        },
        "&:hover": {
          ".MuiOutlinedInput-notchedOutline": {
            border: "1px solid #FFFFFF",
          },
        },
        "&.Mui-focused": {
          ".MuiOutlinedInput-notchedOutline": {
            border: "1px solid #FFFFFF",
          },
        },
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        background: "none",
        boxShadow: "none",
        border: "1px solid rgba(240, 241, 245, 0.1)",
        margin: 0,
        "@media (max-width:480px)": {
          border: "none",
        },
        "&.MuiAccordion-root": {
          borderRadius: "12px",
        },
        "&:before": {
          display: "none",
        },
        "&.Mui-expanded": {
          margin: 0,
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: "0 28px",
        minHeight: "82px",
        "@media (max-width:480px)": {
          minHeight: "41px",
          padding: "0",
        },
        "&.Mui-expanded": {
          boxShadow: "0 1px 0 0 rgba(240, 241, 245, 0.1)",
          minHeight: "82px",
          "@media (max-width:480px)": {
            boxShadow: "none",
            minHeight: "41px",
          },
        },
      },
      content: {
        "&.Mui-expanded": {
          "@media (max-width:480px)": {
            margin: "0px",
          },
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: "20px 28px",
        "@media (max-width:480px)": {
          padding: "16px",
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        ".MuiTabs-indicator": {
          background: paletteDark.tab.indicator.background,
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "none",
        font: '400 14px / 1 "Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif',
        color: paletteDark.tab.button.color,
        "&.Mui-selected": {
          color: paletteDark.tab.button.colorActive,
        },
      },
    },
  },
};
