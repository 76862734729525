import { Box } from "@mui/material";
import React from "react";

import styles from "./PageLoader.module.scss";

const PageLoader = () => {
  return <Box className={styles.container}></Box>;
};

export default PageLoader;
