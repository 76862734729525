import mixpanel from "mixpanel-browser";
import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getUsersMeCurrencyCode,
  getUsersMeId,
} from "redux/selectors/usersSelectors";
import AnalyticsService from "services/Analytics.service";

const GTM_ID = process.env.REACT_APP_GTM_ID;
const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

const Analytics = () => {
  let location = useLocation();
  const userId = useSelector(getUsersMeId);
  const currency = useSelector(getUsersMeCurrencyCode);

  useEffect(() => {
    TagManager.initialize({
      gtmId: GTM_ID,
    });

    mixpanel.init(MIXPANEL_TOKEN);
  }, []);

  useEffect(() => {
    AnalyticsService.page(`${location.pathname}`, {
      userId,
      currency,
    });
  }, [currency, location.pathname, userId]);

  return <></>;
};

export default Analytics;
