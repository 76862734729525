import { USER_BLOCKED } from "constants";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setServerError } from "redux/appSlice";

import { api } from "./api.service";

const ResponseInterceptor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    api.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const serverErrorData = error?.response?.data || error?.data;
        if (serverErrorData?.error === USER_BLOCKED) {
          dispatch(setServerError(serverErrorData));
        }
        if (error?.response) {
          if (error.response.status === 503) {
            return navigate("/503");
          }
          if (error.response.status === 404) {
            navigate("/404", { replace: true });
          }

          if (error?.response?.data) {
            return Promise.reject(error?.response);
          }
        }
        // if (error && !serverErrorData && error !== 401) {
        //   return navigate("/503");
        // }
        return Promise.reject(error);
      }
    );
  }, []);
  return <></>;
};

export default ResponseInterceptor;
