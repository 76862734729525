const colors = {
  neutralWhite: "#FFFFFF",
  neutralGray50: "#F8FAFC",
  neutralGray100: "#F1F5F9",
  neutralGray200: "#E2E8F0",
  neutralGray300: "#CBD5E1",
  neutralGray400: "#94A3B8",
  neutralGray500: "#64748B",
  neutralGray600: "#475569",
  neutralGray700: "#334155",
  neutralGray800: "#1E293B",
  neutralGray900: "#0F172A",
  neutralGray950: "#020617",
  primary600: "#7C3AED",
  primary500: "#8B5CF6",
  primary400: "#A78BFA",
  primary200: "#DDD6FE",
  primary100: "#F5F3FF",
  gradientPrimary500: "linear-gradient(128deg, #AD42FF 0%, #5F89F7 100%)",
  gradientPrimary400: "linear-gradient(128deg, #9C2CF3 0%, #3A6FF9 100%)",
  gradientPrimary400start: "#9C2CF3",
  gradientPrimary400end: "#3A6FF9",
  gradientPrimary500start: "#AD42FF",
  gradientPrimary500end: "#5F89F7",
  doubleGradient:
    "linear-gradient(128deg, #AD42FF 0%, #5F89F7 100%), linear-gradient(128deg, #9C2CF3 0%, #3A6FF9 100%)",
  brightRed: "#EF4444",
  errorText: "#EE5261",
  primary: "#AD42FF",
  white: "#FFFFFF",
};

export default colors;
