import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchCountries, setLoader, setSnackbar } from "redux/appSlice";
import { fetchReactions } from "redux/feedSlice";
import { getGlobalUnreadCountMessages } from "redux/messengerSlice";
import { fetchUser, fetchUserMe, updateUserProfile } from "redux/usersSlice";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import { getUserLocale } from "utils/langHelper";

import {
  COOKIE,
  COOKIE_ACCEPTED,
  PATHS,
  SNACKBAR_TYPE,
  USER_BLOCKED,
  USER_STATUS_BLOCKED,
} from "../constants";
import { getServerError } from "../redux/selectors/appSelectors";
import { getUsersMeStatus } from "../redux/selectors/usersSelectors";
import { useTelegram } from "../utils/hooks/useTelegram";

export const GlobalRequests = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const { setLocalization } = useContext(LocalizationContext);
  const { user: telegramUser } = useTelegram();

  const userMeStatus = useSelector(getUsersMeStatus);
  const serverError = useSelector(getServerError);

  const isAuth = isAuthenticated();

  useEffect(() => {
    if (isAuth) {
      dispatch(getGlobalUnreadCountMessages());
      dispatch(fetchCountries()).catch((err) =>
        console.error("Cannot get countries", err)
      );
      dispatch(fetchReactions()).catch((err) =>
        console.error("Cannot get reactions", err)
      );
    }
  }, [dispatch, isAuth]);

  useEffect(() => {
    if (isAuth) {
      dispatch(setLoader(true));
      dispatch(fetchUser())
        .unwrap()
        .then((res) => {
          const { language, email } = res;
          const userLocale = getUserLocale();
          if (email && window.sib) {
            window.sib.email_id = email;
          }
          const selectedLang =
            localStorage.getItem("selected-language") || language || userLocale;

          dispatch(
            updateUserProfile({
              language: selectedLang,
            })
          ).then((res) => {
            if (res?.payload?.language) {
              setLocalization(res.payload.language);
            } else {
              setLocalization(selectedLang);
            }
          });

          localStorage.setItem("ii-language", userLocale);
        })
        .finally(() => dispatch(setLoader(false)));
      dispatch(fetchUserMe());
    }
  }, [dispatch, isAuth, setLocalization]);

  useEffect(() => {
    const cookie = localStorage.getItem(COOKIE);

    if (cookie !== COOKIE_ACCEPTED && !telegramUser) {
      dispatch(
        setSnackbar({
          open: true,
          unclosable: true,
          autoHideDuration: null,
          type: SNACKBAR_TYPE.COOKIE,
          className: "cookieSnackbar",
        })
      );
    }
  }, [dispatch, location.pathname, telegramUser]);

  useEffect(() => {
    if (serverError === USER_BLOCKED || userMeStatus === USER_STATUS_BLOCKED) {
      navigate(PATHS.BLOCKED, {
        replace: true,
      });
    }
  }, [serverError, userMeStatus]);

  return <></>;
};
