import { palette } from "../palette";

export const paletteDark = {
  ...palette,
  main: "#111322",
  contrastText: "#FFFFFF",
  placeholder: "rgba(255, 255, 255, 0.5)",
  /*---------------*/
  mainBackground: "#111322",
  mainTextColor: "#fff",
  containerBorderColor: "rgba(80, 90, 169, 0.5)",
  containerBackground: "#21264e",
  header: {
    background: "rgba(17, 19, 34, 0.7)",
    boxShadow: "none",
    backdropFilter: "blur(10px)",
    icon: "#fff",
  },
  searchBar: {
    button: "#111322",
    buttonIcon: "#F0F1F5",
  },
  post: {
    translationIcon: {
      fill: "#fff",
    },
    divider: {
      color: "rgba(255, 255, 255, 0.2)",
    },
    longTextShadow: "linear-gradient(0deg, #21264e, transparent)",
  },
  commentsCount: {
    icon: {
      stroke: "#fff",
    },
  },
  share: {
    button: {
      icon: {
        stroke: "#fff",
      },
    },
  },
  reactionsInfo: {
    icon: {
      stroke: "#fff",
      rectFill: "#21264e",
    },
  },
  tab: {
    panel: {
      background: "rgba(33, 38, 78, 0.5)",
    },
    button: {
      color: "#fff",
      colorActive: "#70e8fc",
    },
    indicator: {
      background: "#70e8fc",
    },
  },
  viewAll: {
    color: "#70E8FC",
  },
  customTooltip: {
    background:
      "linear-gradient(0deg, rgba(80, 90, 169, 0.5) 0%, rgba(80, 90, 169, 0.5) 100%), #111322",
    boxShadow: "0 4px 24px 0 #111322",
    arrowColor: "rgba(80, 90, 169, 0.5)",
    color: "#fff",
  },
  backBtn: {
    color: "#fff",
  },
  surface: {
    background: "#181C38",
    backgroundDarker: "#191c38",
    border: "rgba(80, 90, 169, 0.35)",
  },
  badge: {
    background: "rgba(80, 90, 169, 0.25)",
    border: "rgba(80, 90, 169, 0.20)",
  },
  profileNav: {
    paper: "#111322",
    border: "#f0f1f5",
    icons: "white",
    info: {
      icon: "#f1fc70",
    },
    avatar: {
      background: "#21264e",
    },
  },
  accent: "#70e8fc",
  fullCard: {
    border: "rgba(80, 90, 169, 0.5)",
    button: {
      background: "rgba(80, 90, 169, .25)",
    },
  },
  cardProgress: {
    main: "#f1fc70",
    progressBg: "#737696",
  },
  progress: {
    background: "rgba(240, 241, 245, 0.2)",
    color: "#27e7c4",
    textColor: "rgba(255, 255, 255, 0.7)",
  },
  bottomMenu: {
    background: "#111322",
    boxShadow: "none",
    item: {
      color: "rgba(255, 255, 255, 0.5)",
      colorActive: "#fff",
      svg: {
        path: {
          stroke: "rgba(255, 255, 255, 0.5)",
          strokeActive: "rgba(255, 255, 255)",
        },
      },
      activeIndicator: {
        background: "#70e8fc",
        boxShadow: "0 2px 4px 1px rgba(112, 232, 252, 0.25)",
      },
    },
  },
  challenge: {
    lightColor: "rgba(255, 255, 255, 0.7)",
  },
  chat: {
    hoverBackground: "rgba(33, 38, 78, 0.5)",
    hoverShadow:
      "linear-gradient(270deg, #191c38 53.08%, rgba(24, 28, 56, 0) 126.15%)",
  },
  auth: {
    separator: "#575A75",
    background: "#1f2446",
  },
  google: {
    authBackground: "rgba(80, 90, 169, 0.1)",
    color: "#fff",
  },
  emailBanner: {
    background:
      "linear-gradient(128deg, #AD42FF 0%, #5F89F7 100%), linear-gradient(128deg, #9C2CF3 0%, #3A6FF9 100%))",
  },
};
