import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";
import { getUserLocale } from "utils/langHelper";

const LangService = () => {
  const { setLocalization } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const dataStatusSuccess = useSelector(
    (state) => state.users.status === "succeeded"
  );
  const language = useSelector((state) => state.users.entities.language);
  useEffect(() => {
    if (dataStatusSuccess) {
      if (language) {
        setLocalization(language);
      } else {
        const language = getUserLocale();
        dispatch(updateUserProfile({ language }));
        setLocalization(language);
      }
    }
  }, [dataStatusSuccess]);

  return null;
};

export default LangService;
